import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Typography } from "@material-ui/core/index"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Typography variant={"h3"}>Hi people</Typography>
    <Typography>Welcome to your new Gatsby site.</Typography>
    <Typography>Now go build something great.</Typography>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <Typography>
      <Link to="/page-2/">
        <Typography>Go to page 2</Typography>
      </Link>{" "}
      <br />
      <Link to="/using-typescript/">
        <Typography>Go to "Using TypeScript"</Typography>
      </Link>
    </Typography>
  </Layout>
)

export default IndexPage
